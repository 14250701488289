import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(home)": [5],
		"/(noAuth)/accessibilite": [6,[2]],
		"/admin": [12,[3]],
		"/admin/domains": [13,[3]],
		"/admin/stats": [14,[3]],
		"/admin/users/create": [15,[3]],
		"/admin/users/list": [16,[3]],
		"/admin/users/metrics": [17,[3]],
		"/association/[identifier]": [18],
		"/auth/activate/[token]": [19,[4]],
		"/auth/forget-password": [20,[4]],
		"/auth/login": [21,[4]],
		"/auth/reset-password/[token]": [22,[4]],
		"/auth/signup": [23,[4]],
		"/(noAuth)/cgu": [~7,[2]],
		"/(noAuth)/contact": [8,[2]],
		"/etablissement/[identifier]": [24],
		"/(noAuth)/mentions-legales": [9,[2]],
		"/(noAuth)/politique-de-confidentialite": [~10,[2]],
		"/search/[name]": [25],
		"/(noAuth)/statistiques": [~11,[2]],
		"/user/profile": [26]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';